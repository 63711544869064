@font-face {
    font-family: myplayer;
    src: url("../fonts/Stolzl-Book.otf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: myplayer;
    font-weight: 500;
    src: url("../fonts/Stolzl-Regular.otf") format("opentype");
}

@font-face {
    font-family: myplayer;
    font-weight: 600;
    src: url("../fonts/Stolzl-Bold.otf") format("opentype");
}

body {
    font: 16px myplayer;
    margin: 0px;
    font-weight: normal;
}

.footer {
    position: fixed;
    width: calc(100% - 40px);
    background-color: #2b2b2b;
    color: white;
    bottom: 0px;
    text-align: right;
    padding: 20px 20px;
}

.footer > a {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 5px;
    color: white;
    text-decoration: none;
}

.footer > a > img {
    height: 35px;
    -webkit-transition: all 0.3s ease;
}

.footer > a > img:hover {
    -webkit-transform: scale(1.1);
    opacity: 0.85;
}

.footer > a > span {
    font-size: 12px;
    color:white;
    -webkit-transition: all 0.3s ease;
}

.footer > a > span:hover {
    -webkit-transform: scale(1.1);
    opacity: 0.85;
}

a#myplayer_logo {
    float: left;
}

#signInBlurb{
    opacity: 0;
    text-align: center;
    width: 100%;
    margin-top: 100px;
    margin-bottom: -200px;
}
#signInBlurb > img {
    display: inline-block;
    width: 100px;
}

.register_title {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    color: white;
    margin-top: 10px;
    color: black;
    font-weight: 600;
    margin-top: 20px;
}

.lds-ring {
  display: inline-block;
  position: absolute;
  width: 64px;
  height: 64px;
  left:50%;
  top:50%;
  margin-left:-32px;
  margin-top:-32px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid black;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: black transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
